import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Form, Button, Image, Alert } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { PostAuthUserRegister } from '../../utils/api'
import { useAnalyticsPushEvent, useAnalyticsSetUser } from '../../analytics'
import './index.css'

function Register () {
    const navigate = useNavigate()
    const pushEvent = useAnalyticsPushEvent()
    const setUser = useAnalyticsSetUser()
    const [registerStatus, setRegisterStatus] = useState('')
    // const [ipAddress, setIpAdress] = useState('0.0.0.0')
    useEffect(() => {
        pushEvent('View Register')
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = async (event) => {
        event.preventDefault()

        const email = event.target.elements.email.value
        const fullName = event.target.elements.full_name.value
        const role = event.target.elements.role.value
        const phoneNumber = event.target.elements.phone_number.value
        const password = event.target.elements.password.value

        if (!email) {
            setRegisterStatus('Email wajib diisi')
            return
        }

        if (!fullName) {
            setRegisterStatus('Nama wajib diisi')
            return
        }
        if (!role) {
            setRegisterStatus('Jabatan wajib diisi')
            return
        }
        if (!password) {
            setRegisterStatus('Password wajib diisi')
            return
        }

        try {
            const requestBodyRegister = { email, role, name: fullName, phone_number: phoneNumber, password }

            const { data: responseData } = await Axios.post(PostAuthUserRegister(), requestBodyRegister)
            if (!responseData.data || !responseData.data.token) {
                setRegisterStatus('Terjadi kesalahan. Mohon melakukan pengisian form kembali')
                return
            }

            Cookies.set('token', responseData.data.token, { expires: new Date(responseData.data.expired_at) })
            localStorage.setItem('partner', JSON.stringify(responseData.data.user))

            // Sets amplitude user
            setUser(responseData.data.user)

            // Logs event login.
            pushEvent('User Enter Dashboard', {
                user: responseData.data.user,
                enterFrom: 'register'
            })
            navigate('/')
        } catch (err) {
            const errResData = err?.response?.data
            let text
            if (errResData && errResData.data && errResData.data.validation_error) {
                text = errResData.data.validation_error
            } else if (errResData && errResData.data && errResData.data.message) {
                text = errResData.data.message
            } else if (errResData && errResData.message) {
                text = errResData.message
            } else {
                text = err.message
            };

            setRegisterStatus(text)
        }
    }

    const handleDirectToLogin = () => {
        pushEvent('Navigate to Login', { directFrom: 'registerPage' })
        navigate('/login')
    }

    return (
        <Row className="align-items-center mx-0">
            <Col md={6} className="d-none d-md-block px-0">
                <Image src="/img/bg-login.png" fluid className="object-fit-cover vh-100" />
            </Col>
            <Col md={{ span: 4, offset: 1 }}>
                <Row>
                    <Col xs={12} className="d-sm-block d-md-none mt-5"></Col>
                    <Col xs={{ offset: 1, span: 10 }} md={{ offset: 1, span: 10 }}>
                        <h3>Coba Verihubs Sekarang Gratis!</h3>
                        {registerStatus && (
                            <Alert variant="danger" className="mt-3">
                                <strong className="text-danger"><FontAwesomeIcon icon={faCircleExclamation} size="lg" /></strong> {registerStatus}
                            </Alert>
                        )}
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mt-3">
                                <Form.Label>Email*</Form.Label>
                                <Form.Control name="email" type="email" placeholder="Masukkan email kerja Anda" autoFocus autoComplete="off" />
                            </Form.Group>
                            <Form.Group className="mt-3">
                                <Form.Label>Nama*</Form.Label>
                                <Form.Control name="full_name" type="text" placeholder="Masukkan nama Anda" autoComplete="off" />
                            </Form.Group>
                            <Form.Group className="mt-3">
                                <Form.Label>Jabatan*</Form.Label>
                                <Form.Control name="role" type="text" placeholder="Masukkan jabatan Anda" autoComplete="off" />
                            </Form.Group>
                            <Form.Group className="mt-3">
                                <Form.Label>Nomor Telepon</Form.Label>
                                <Form.Control name="phone_number" type="text" placeholder="Masukkan nomor telepon Anda" autoComplete="off" />
                            </Form.Group>
                            <Form.Group className="mt-3">
                                <Form.Label>Password*</Form.Label>
                                <Form.Control name="password" type="password" placeholder="Masukkan password Anda" autoComplete="off" />
                            </Form.Group>

                            <Button variant="primary" type="submit" className="mt-3">
                Submit
                            </Button>
                        </Form>
                        <p className="text-left text-black-50 mt-3 d-flex align-items-center">Sudah punya akun?
                            <Button size="sm" variant="link"
                                onClick={handleDirectToLogin}>Masuk</Button>
                        </p>
                    </Col>
                </Row>
            </Col >
        </Row >
    )
}

export default Register
