import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Form, Button, Image, Alert } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { PostAuthUserPreLogin, PostAuthUserLogin } from '../../utils/api'
import { useAnalyticsPushEvent, useAnalyticsSetUser } from '../../analytics'
import CustomInput from '../../components/Shared/CustomInput'
import './index.css'

function Login () {
    const navigate = useNavigate()
    const pushEvent = useAnalyticsPushEvent()
    const setUser = useAnalyticsSetUser()
    const [credential, setCredential] = useState({
        email: '',
        password: '',
        token_code: ''
    })
    const [loginStatus, setLoginStatus] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [show2FAForm, set2FAForm] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    useEffect(() => {
        pushEvent('View Login')
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const initializeSuccessLogin = (responseData) => {
        Cookies.set('token', responseData.data.token, { expires: new Date(responseData.data.expired_at) })
        localStorage.setItem('partner', JSON.stringify(responseData.data.user))

        // Sets amplitude user.
        setUser(responseData.data.user)

        // Logs event login.
        pushEvent('User Enter Dashboard', {
            user: responseData.data.user,
            enterFrom: 'login'
        })

        navigate('/')
    }

    const handleInput = (e) => {
        e.preventDefault()

        setCredential((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    const setErrorMessage = (errResData, err) => {
        let text
        if (errResData && errResData.data && errResData.data.validation_error) {
            text = errResData.data.validation_error
        } else if (errResData && errResData.data && errResData.data.message) {
            text = errResData.data.message
        } else if (errResData && errResData.message) {
            text = errResData.message
        } else {
            text = err.message
        };

        setLoginStatus(text)
    }

    const handleSubmit = async (event) => {
        event.preventDefault()

        if (!credential.email) {
            setLoginStatus('Email wajib diisi')
            return
        }

        if (!credential.password) {
            setLoginStatus('Password wajib diisi')
            return
        }

        try {
            setIsSubmitting(true)
            const { data: responseData, status: responseStatus } = await Axios.post(PostAuthUserPreLogin(), { username: credential.email, password: credential.password })

            if (responseStatus === 202) {
                setLoginStatus('')
                set2FAForm(true)
            } else if (responseStatus === 200) {
                initializeSuccessLogin(responseData)
            }
        } catch (err) {
            const errResData = err?.response?.data
            setErrorMessage(errResData, err)
        } finally {
            setIsSubmitting(false)
        }
    }

    const handleSubmitLoginWith2FA = async (event) => {
        event.preventDefault()

        if (!credential.token_code) {
            setLoginStatus('Kode 2FA wajib diisi')
            return
        }

        try {
            setIsSubmitting(true)
            const { data: responseData } = await Axios.post(PostAuthUserLogin(), { username: credential.email, password: credential.password, token_code: credential.token_code })

            if (!responseData.data || !responseData.data.token) {
                setLoginStatus('Email dan password tidak valid')
                return
            }
            initializeSuccessLogin(responseData)
        } catch (err) {
            const errResData = err?.response?.data
            setErrorMessage(errResData, err)
        } finally {
            setIsSubmitting(false)
        }
    }

    const handleDirectToRegister = () => {
        pushEvent('Navigate to Register', { directFrom: 'loginPage' })
        navigate('/register')
    }

    return (
        <Row className="align-items-center mx-0">
            <Col md={6} className="d-none d-md-block px-0">
                <Image src="/img/bg-login.png" fluid className="object-fit-cover vh-100" />
            </Col>
            <Col md={{ span: 4, offset: 1 }}>
                <Row>
                    <Col xs={12} className="d-sm-block d-md-none mt-5"></Col>
                    <Col xs={{ offset: 1, span: 10 }} md={{ offset: 1, span: 10 }}>
                        <h3>Selamat Datang di Verihubs</h3>
                        {
                            !show2FAForm && (
                                <p className="text-left text-black-50 mt-3 d-flex align-items-center">
                            Belum punya akun?
                                    <Button size="sm" variant="link"
                                        onClick={handleDirectToRegister}>Coba Gratis</Button>
                                </p>
                            )
                        }
                        {loginStatus && (
                            <Alert variant="danger" className="mt-3">
                                <strong className="text-danger"><FontAwesomeIcon icon={faCircleExclamation} size="lg" /></strong> {loginStatus}
                            </Alert>
                        )}

                        {
                            !show2FAForm
                                ? (
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group className="mt-3">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                name="email"
                                                type="email"
                                                placeholder="Masukkan email Anda"
                                                autoFocus
                                                autoComplete="off"
                                                onChange={handleInput}
                                                value={credential.username}
                                                disabled={isSubmitting}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mt-3">
                                            <Form.Label>Password</Form.Label>
                                            <CustomInput
                                                name="password"
                                                placeholder="Masukkan password Anda"
                                                type={showPassword ? 'text' : 'password'}
                                                value={credential.password}
                                                required={true}
                                                autoComplete="off"
                                                onChange={handleInput}
                                                icon={<FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />}
                                                clickableIcon
                                                onIconClick={() => setShowPassword(!showPassword)}
                                                disabled={isSubmitting}
                                            />
                                        </Form.Group>
                                        <Button variant="primary" type="submit" className="mt-3" disabled={isSubmitting}>
                Login
                                        </Button>
                                    </Form>

                                )
                                : (
                                    <Form onSubmit={handleSubmitLoginWith2FA}>
                                        <Form.Group className="mt-3">
                                            <Form.Label>Kode Two Factor Authentication</Form.Label>
                                            <CustomInput
                                                name="token_code"
                                                type="text"
                                                required
                                                placeholder="Masukkan kode 2FA Anda"
                                                value={credential.token_code}
                                                onChange={handleInput}
                                                disabled={isSubmitting}
                                                inputClassName="my-3"
                                                onlyAcceptNumbers
                                            />
                                        </Form.Group>
                                        <Button variant="primary" type="submit" className="mt-3" disabled={isSubmitting}>
                Login
                                        </Button>
                                    </Form>
                                )
                        }

                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default Login
